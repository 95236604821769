/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { controller } from 'types/remote-T';
type rowDataType = controller | any | undefined;
const useOnModal = () => {
  const [onAddModal, setOnAddModal] = useState(false);
  const [onDetailModal, setOnDetailModal] = useState(false);
  const [onUpdateModal, setOnUpdateModal] = useState(false);
  const [onDeleteModal, setOnDeleteModal] = useState(false);
  const [onExportExcelModal, setOnExportExcelModal] = useState(false);
  const [onDisplaySettingModal, setOnDisplaySettingModal] = useState(false);
  const [onRunningColorSettingModal, setOnRunningColorSettingModal] =
    useState(false);

  const [rowData, setRowData] = useState<rowDataType>();

  const onClickAddModal = () => {
    setOnAddModal(!onAddModal);
  };

  const onClickDetailModal = (data?: any) => {
    if (data) {
      setRowData(data);
      setOnDetailModal(!onDetailModal);
      return;
    }
    setRowData(undefined);
    setOnDetailModal(!onDetailModal);
  };

  const onClickUpdateModal = (data?: any) => {
    if (data) {
      setRowData(data);
      setOnUpdateModal(!onUpdateModal);
      return;
    }
    setRowData(undefined);
    setOnUpdateModal(!onUpdateModal);
  };

  const onClickDeleteModal = async (data?: any) => {
    if (data) {
      setRowData(data);
      setOnDeleteModal(!onDeleteModal);
      return;
    }
    setRowData(undefined);
    setOnDeleteModal(!onDeleteModal);
  };

  const onClickExportExcelModal = async () => {
    setRowData(undefined);
    setOnExportExcelModal(!onExportExcelModal);
  };

  const onClickDisplaySettingModal = async (data?: any) => {
    if (data) {
      setRowData(data);
      setOnDisplaySettingModal(!onDisplaySettingModal);
      return;
    }
    setRowData(undefined);
    setOnDisplaySettingModal(!onDisplaySettingModal);
  };

  const onClickRunningColorSettingModal = async () => {
    setRowData(undefined);
    setOnRunningColorSettingModal(!onRunningColorSettingModal);
  };

  return {
    rowData,
    onAddModal,
    onDetailModal,
    onUpdateModal,
    onDeleteModal,
    onExportExcelModal,
    onDisplaySettingModal,
    onRunningColorSettingModal,
    setRowData,
    onClickAddModal,
    onClickDetailModal,
    onClickUpdateModal,
    onClickDeleteModal,
    onClickExportExcelModal,
    onClickDisplaySettingModal,
    onClickRunningColorSettingModal,
  };
};

export default useOnModal;
