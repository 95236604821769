import Modal from 'components/common/Modal';
import { colors } from 'constants/colors';
import React, { useState, ChangeEvent, KeyboardEvent } from 'react';
import { SEARCH_ENTERPRISE } from 'services/table-text';
import { styled } from 'styled-components';
import { companyInfo, companyList } from 'types/account-T';
import { tableHeaderType } from 'types/table-T';
import { ReactComponent as SearchSVG } from 'assets/img/search.svg';
import ModalTitle from './common/ModalTitle';
import Pagination from 'components/common/Pagination';
import { useEnterprise } from 'apiHooks/useEnterprise';
interface IProps {
  onChangeCompanyInfo: (data: companyInfo) => void;
  onClose: () => void;
}

const SearchEnterprise: React.FC<IProps> = ({
  onChangeCompanyInfo,
  onClose,
}) => {
  const [companyList, setCompanyList] = useState<companyInfo[]>([]);
  const [searchText, setSearchText] = useState('');
  const [curPage, setCurPage] = useState(1);
  const articlePerPage = 10;

  const { data = [], isLoading, refetch } = useEnterprise(setCompanyList);

  const handleClickSearch = () => {
    if (searchText.length === 0) {
      refetch();
    }

    setCurPage(1);
    setCompanyList(
      data.filter((item: companyInfo) => item.name.includes(searchText)),
    );
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      handleClickSearch();
    }
  };

  const filteredCompanyList = companyList.slice(
    (curPage - 1) * articlePerPage,
    articlePerPage * curPage,
  );

  return (
    <Modal>
      <Wrap>
        <ModalTitle title="기업정보 조회" onClose={onClose} />
        <SearchWrap>
          <span>기업명</span>
          <input
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchText(e.target.value)
            }
            onKeyDown={handleKeyDown}
          />
          <SearchIcon onClick={handleClickSearch} />
        </SearchWrap>
        <TableBgWrap>
          <Table>
            <HeaderWrap>
              <tr>
                {SEARCH_ENTERPRISE.map((data: tableHeaderType) => (
                  <Th key={data.en}>{data.kr}</Th>
                ))}
              </tr>
            </HeaderWrap>
            <BodyWrap>
              {filteredCompanyList.map((data: companyInfo, idx: number) => (
                <tr key={data.id} onClick={() => onChangeCompanyInfo(data)}>
                  {SEARCH_ENTERPRISE.map((hData: tableHeaderType) => {
                    const keys = Object.keys(data);
                    const hasDataKey = keys.includes(hData.en);
                    const keyData = data[hData.en as keyof companyInfo];

                    if (hData.en === 'Index') {
                      const rowIdx = (curPage - 1) * 10 + (idx + 1);
                      return <Td key={rowIdx}>{rowIdx}</Td>;
                    }

                    if (hasDataKey) {
                      return <Td key={hData.kr}>{keyData}</Td>;
                    }
                  })}
                </tr>
              ))}
            </BodyWrap>
          </Table>
        </TableBgWrap>
        <Pagination
          curPage={curPage}
          data={companyList}
          articlePerPage={articlePerPage}
          setCurPage={setCurPage}
        />
        <ModalBtn onClick={onClose}>취소</ModalBtn>
      </Wrap>
    </Modal>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.CARD_BG_PRIMARY};
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  padding: 24px 48px;
  margin-top: 36px;
  height: 90%;
  width: 650px;
  height: 580px;
  gap: 18px;
  border-radius: 16px;
`;

const SearchWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  color: #9a9a9a;

  > input {
    width: 300px;
    border: 2px solid rgb(208, 206, 206);
    padding: 4px 12px;
    padding-right: 34px;
    font-size: 13px;
    border-radius: 4px;
  }
`;

const SearchIcon = styled(SearchSVG)`
  position: absolute;
  width: 13px;
  height: 13px;
  cursor: pointer;
  top: 6px;
  right: 205px;
  z-index: 100;
`;

const TableBgWrap = styled.div`
  height: 85%;
  background-color: ${colors.WHITE};
  color: ${({ theme }) => theme.BLACK};
`;

const Table = styled.table`
  width: 100%;
  max-height: 100%;
  /* height: 100%; */
`;

const HeaderWrap = styled.thead`
  background-color: ${({ theme }) => theme.TABLE_HEADER_BG};
  color: ${({ theme }) => theme.WHITE};
`;

const Th = styled.th`
  padding: 8px 4px;
  font-size: 15px;
`;

const Td = styled.td`
  text-align: center;
  padding: 9px 3px;
  font-size: 13px;
`;

const BodyWrap = styled.tbody`
  width: 100%;
  background-color: ${colors.WHITE};

  > tr {
    cursor: pointer;
    &:hover {
      background-color: #f2f5f8;
    }

    > td:nth-child(1) {
      width: 10%;
      text-align: center;
    }

    > td:nth-child(2) {
      width: 60%;
    }

    > td:nth-child(3) {
      width: 30%;
    }
  }
`;

const ModalBtn = styled.button`
  width: 80px;
  padding: 8px 24px;
  align-self: flex-end;
  color: ${({ theme }) => theme.WHITE};
  background: ${({ theme }) => theme.BUTTON_THIRD};
  border-radius: 6px;
  border: 0;
  outline: 0;
  font-weight: 500;
`;

export default SearchEnterprise;
