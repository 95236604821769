/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SetStateAction } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getCiImg,
  getDashboardData,
  getEquipImg,
  getFilteredDashboard,
  getMainDashboardData,
  getMainDashboardStatistics,
  getMainDashboardStatisticsTypes,
  patchIntegrationToggle,
  postChangeOrderSensors,
  postEquipment,
  putEquipment,
} from 'apis/dashboard-rest';
import { queryKeys } from 'services/queryKeys';
import { dashboardData, sensor } from 'types/dashboard-T';
import { deleteEquipmentImg, postEquipmentImg } from 'apis/equipment-rest';
import { cvtInitAlarmRules } from 'services/miscFunc';
import * as _ from 'lodash';
import store from 'store/configureStore';

export const useDashboard = (
  dashboardId: number,
  setTable: React.Dispatch<SetStateAction<dashboardData>>,
  refreshTime?: number,
  isEnabled?: boolean,
) => {
  return useQuery(
    [queryKeys.DASHBOARD, dashboardId],
    () => getDashboardData(dashboardId),
    {
      onSuccess: (data: dashboardData) => setTable(data),
      enabled: dashboardId !== -1 && isEnabled,
      refetchInterval: refreshTime,
      refetchIntervalInBackground: true,
    },
  );
};

export const useMainDashboard = (
  refreshTime?: number | false,
  isEnabled?: boolean,
) => {
  const companyId = store.getState().accountInfo.info.companyId;

  const queryResult = useQuery(
    [queryKeys.MAIN, companyId],
    () => getMainDashboardData(companyId),
    {
      enabled: companyId !== -1 && isEnabled,
      refetchInterval: refreshTime,
      refetchIntervalInBackground: true,
    },
  );

  return {
    ...queryResult,
    lastUpdateTime: new Date().toLocaleString(),
  };
};

// export const useMainDashboardStatistics = (
//   setData: React.Dispatch<SetStateAction<any>>,
//   prms: any,
//   refreshTime?: number,
//   isEnabled?: boolean,
// ) => {
//   const companyId = store.getState().accountInfo.info.companyId;

//   return useQuery(
//     [queryKeys.MAIN, companyId, 'statistics'],
//     () => getMainDashboardStatistics(companyId, prms),
//     {
//       onSuccess: (data: any) => {
//         if (data.success) {
//           setData(data.data);
//         }
//       },
//       enabled: companyId !== -1 && isEnabled,
//       refetchInterval: refreshTime,
//       refetchIntervalInBackground: true,
//     },
//   );
// };

// export const useFilteredDashboard = (setTable: any) => {
//   return useQuery(
//     [queryKeys.DASHBOARD, 'filtered-data'],
//     () => getFilteredDashboard(),
//     {
//       onSuccess: (data) => setTable(data),
//     },
//   );
// };

export const useCiImg = () => {
  return useQuery([queryKeys.DASHBOARD, 'ci-img'], getCiImg);
};

export const useEquipImg = (dashboardId: number) => {
  return useQuery([queryKeys.DASHBOARD, `equipImg${dashboardId}`], () =>
    getEquipImg(dashboardId),
  );
};
interface standardSensors {
  dashboardId: number;
  setTableData: React.Dispatch<SetStateAction<sensor[]>>;
  rulesData: any;
  refreshTime?: number;
  isEnabled?: boolean;
}

export const useStandardSensors = ({
  dashboardId,
  setTableData,
  rulesData,
  refreshTime,
  isEnabled,
}: standardSensors) => {
  return useQuery(
    [`${queryKeys.DASHBOARD}-standard`, dashboardId],
    () => getDashboardData(dashboardId),
    {
      onSuccess: (data: dashboardData) => {
        const applyRulesData = cvtInitAlarmRules(data.sensors, rulesData);
        console.log(data.sensors, rulesData);
        setTableData(_.cloneDeep(applyRulesData));
      },
      enabled: dashboardId !== -1,
      refetchInterval: refreshTime,
      refetchIntervalInBackground: true,
    },
  );
};

export const useMutationEquipment = () => {
  return useMutation((formData: FormData) => postEquipment(formData));
};

export const usePatchEquipment = () => {
  return useMutation((formData: FormData) => putEquipment(formData));
};

export const useMutationImg = () => {
  return useMutation((formData: FormData) => postEquipmentImg(formData));
};

export const useDeleteEquipImg = () => {
  return useMutation((formData: FormData) => deleteEquipmentImg(formData));
};

export const useChangeOrderSensors = () => {
  return useMutation((prms: { orders: number[] }) =>
    postChangeOrderSensors(prms),
  );
};

export const useStatisticsType = () => {
  return useQuery([queryKeys.MAIN, 'statisticsTypes'], () =>
    getMainDashboardStatisticsTypes(),
  );
};

export const usePatchIntegrationToggle = () => {
  return useMutation(() => patchIntegrationToggle());
};
