import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as exclamationSVG } from 'assets/img/exclamation.svg';
import { colors } from 'constants/colors';
import ChangeNewPw from './common/ChangeNewPw';
import { validationPassword } from 'validation/account';
import { accountInfo } from 'store/modules/account';
import { patchPw } from 'apis/account-rest';
import { useNavigate } from 'react-router-dom';
import routePath from 'constants/route';
import { useDispatch } from 'react-redux';
import { customErrorMsg } from 'services/miscFunc';
interface IProps {
  accountInfo: accountInfo;
  onClose: () => void;
}
const ChangeFirstPw: React.FC<IProps> = ({ accountInfo, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pwFormData, setPwFormData] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const { newPassword, confirmPassword } = pwFormData;

  const handleChangePw = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPwFormData({
      ...pwFormData,
      [name]: value,
    });
  };

  const handleClick = async () => {
    const isSamePw = newPassword === confirmPassword;
    const isValidationPw =
      validationPassword(newPassword).status ||
      validationPassword(confirmPassword).status;
    if (!isSamePw) {
      customErrorMsg('새 비밀번호와 비밀번호 확인이 일치하지 않습니다.');
      return;
    }

    if (!isValidationPw) {
      customErrorMsg(
        '특수문자, 영문, 숫자를 포함하여\n 8글자 이상 16글자 이하를 입력해주세요.',
      );
      return;
    }

    const prms = { id: accountInfo.id, password: newPassword };
    const res = await patchPw(prms);
    if (res?.success) {
      navigate(routePath.MAIN);
    }
  };
  return (
    <ChangeNewPw
      newPassword={newPassword}
      confirmPassword={confirmPassword}
      onChangePw={handleChangePw}
      onClick={handleClick}
      onClose={onClose}
    >
      <AlarmWrap>
        <XcircleIcon />
        <p>비밀번호를 변경해주세요!</p>
      </AlarmWrap>
      <DescParagraph>
        {`안전한 사용을 위하여, 최초 비밀번호를 변경해야합니다.
아래에 새 비밀번호를 입력해주세요.`}
      </DescParagraph>
    </ChangeNewPw>
  );
};

const AlarmWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  > p {
    font-size: 16px;
    font-weight: 600;
  }
`;

const XcircleIcon = styled(exclamationSVG)`
  fill: ${colors.ALERT};
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

const DescParagraph = styled.p`
  white-space: pre-line;
  text-align: center;
  color: ${colors.NEUTRAl_500};
  line-height: 20px;
`;

export default ChangeFirstPw;
