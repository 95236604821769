import React, { ChangeEvent } from 'react';
import 'react-datepicker/dist/react-datepicker.module.css';
import { styled } from 'styled-components';
import { ReactComponent as DashSVG } from 'assets/img/dash.svg';
import { ReactComponent as CalendarSVG } from 'assets/img/calendar.svg';
import { calPressBtn, convertDate } from 'services/miscFunc';
import { colors } from 'constants/colors';
interface IProps {
  startDate: number;
  endDate: number;
  children?: React.ReactNode;
  onChangeStartDate: (date: ChangeEvent<HTMLInputElement>) => void;
  onChangeEndDate: (date: ChangeEvent<HTMLInputElement>) => void;
  onClickDateBtn: (beforeNum: number) => void;
}

export const buttonData = [1, 7, 30, 90];

const MyDatePicker: React.FC<IProps> = ({
  startDate,
  endDate,
  children,
  onChangeStartDate,
  onChangeEndDate,
  onClickDateBtn,
}) => {
  return (
    <Wrap>
      <CalendarIcon />
      <input
        type="datetime-local"
        value={convertDate(startDate)}
        max={endDate}
        onChange={onChangeStartDate}
      />
      <DashIcon />
      <input
        type="datetime-local"
        value={convertDate(endDate)}
        onChange={onChangeEndDate}
      />
      <ButtonWrap>
        {buttonData.map((num: number) => (
          <DateBtn
            $isPress={calPressBtn(startDate, endDate) === num}
            key={num}
            onClick={() => onClickDateBtn(num)}
          >
            {`${num}일 전`}
          </DateBtn>
        ))}
      </ButtonWrap>
      {children}
    </Wrap>
  );
};
// 1. 눌렀을 때 눌린 색상
// 2. date를 클릭하고 start,end 중 값이 변경되었을 때 눌린거 해제

//TODO: 버튼정리 및 아이콘 회색

const Wrap = styled.div`
  display: flex;
  align-items: center;
  > input {
    width: 250px;
    height: 30px;
    font-size: 14px;
    text-align: center;
    /* background-color: #9a9a9a; */
    border: 1px solid ${({ theme }) => theme.TEXT_PRIMARY}22 !important;
    background-color: ${({ theme }) => theme.BUTTON_SECONDARY};
    color: ${({ theme }) => theme.BUTTON_SECONDARY_COLOR};
    border: none;
    font-weight: 600;
    border-radius: 4px;
    padding: 2px 8px;
  }

  > span:nth-child(1) {
    font-size: 15px;
    font-weight: 600;
  }
`;

const DashIcon = styled(DashSVG)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  margin: 0 8px;
`;

const CalendarIcon = styled(CalendarSVG)`
  width: 20px;
  height: 20px;
  margin-right: 16px;
  fill: ${({ theme }) => theme.TEXT_PRIMARY};
`;

const ButtonWrap = styled.div`
  padding-left: 16px;
  display: flex;
  gap: 8px;
`;

const DateBtn = styled.div<{ $isPress: boolean }>`
  border: none;
  border: 1px solid ${({ theme }) => theme.TEXT_PRIMARY}22;
  background-color: ${({ theme, $isPress }) =>
    $isPress ? colors.BLUE_DARK : theme.BUTTON_SECONDARY};
  color: ${({ theme }) => theme.BUTTON_SECONDARY_COLOR};
  padding: 6px 8px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;

  transition: background-color 0.3s;

  &:active {
    background: none;
    background-color: ${colors.BLUE_LIGHT};
  }
`;

export default MyDatePicker;
